import { Component, Input, TemplateRef } from '@angular/core';
import { TreeNode } from 'primeng/api';
import * as R from 'ramda';
import { MountedPalletItemType } from '@pt/modules/pile-turner/pile-turner.model';

@Component({
  selector: 'pt-mounted-pallet-table',
  templateUrl: './mounted-pallet-table.component.html',
  styleUrls: ['./mounted-pallet-table.component.scss']
})
export class MountedPalletTableComponent {
  @Input() public set tableData(tableData: TreeNode[]) {
    this.mountedPalletData = this.setExpandFlag(tableData);
  }
  @Input() public buttonTemplate?: TemplateRef<any>;

  public mountedPalletData: TreeNode[] = [];
  public tableExpanded = false;

  public readonly ITEM_TYPE = MountedPalletItemType;

  public expandCollapseTable(): void {
    const clonedMountedPalletData = R.clone(this.mountedPalletData);
    clonedMountedPalletData.map((dataRow: TreeNode) => (dataRow.expanded = !this.tableExpanded));
    this.mountedPalletData = clonedMountedPalletData;
    this.tableExpanded = !this.tableExpanded;
  }

  private setExpandFlag(newTableData: TreeNode[]): TreeNode[] {
    if (R.isNil(newTableData)) {
      return [];
    }
    if (R.isNil(this.mountedPalletData)) {
      return newTableData;
    }

    return newTableData.map((newItem) => {
      newItem.expanded = this.mountedPalletData.find((item) => item.data.id === newItem.data.id)?.expanded;
      return newItem;
    });
  }
}
