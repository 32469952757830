import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { SupplyComponent } from '@pt/modules/pile-turner/containers/supply/supply.component';
import { WorkCenterCardComponent } from '@pt/modules/pile-turner/containers/work-center-list/components/work-center-card/work-center-card.component';
import { PileTurnerComponent } from '@pt/modules/pile-turner/pile-turner.component';
import { PileTurnerService } from '@pt/modules/pile-turner/services/pile-turner/pile-turner.service';
import { SupplyService } from '@pt/modules/pile-turner/services/supply/supply.service';
import { SharedModule } from '@pt/shared/shared.module';
import { MaterialTableComponent } from './containers/pile-turner/components/material-table/material-table.component';
import { TileComponent } from './containers/pile-turner/components/tile/tile.component';
import { MachineLocationModalComponent } from './containers/supply/components/machine-location-modal/machine-location-modal.component';
import { MountingMaterialComponent } from './containers/supply/components/mounting-material/mounting-material.component';
import { CreatingDummyModalComponent } from './containers/work-center-details/components/creating-dummy-modal/creating-dummy-modal.component';
import { PileTurnerDismountButtonComponent } from './containers/work-center-details/components/dismount-button/pile-turner-dismount-button.component';
import { DismountContainerModalComponent } from './containers/work-center-details/components/dismount-container-modal/dismount-container-modal.component';
import { PalletListModalComponent } from './containers/work-center-details/components/pallet-list-modal/pallet-list-modal.component';
import { ProductionOrderListComponent } from './containers/work-center-details/components/production-order-list/production-order-list.component';
import { ProductionOrderComponent } from './containers/work-center-details/components/production-order/production-order.component';
import { ReplaceDummyModalComponent } from './containers/work-center-details/components/replace-dummy-modal/replace-dummy-modal.component';
import { SelectedPalletComponent } from './containers/work-center-details/components/selected-pallet/selected-pallet.component';
import { WorkCenterDetailsComponent } from './containers/work-center-details/work-center-details.component';
import { PalletCounterComponent } from './containers/work-center-list/components/pallet-counter/pallet-counter.component';
import { WorkCenterListComponent } from './containers/work-center-list/work-center-list.component';
import { PalletListComponent } from './containers/work-center-details/components/pallet-list/pallet-list.component';
import { MountedPalletTableComponent } from './containers/work-center-details/components/mounted-pallet-table/mounted-pallet-table.component';
import { DismountDummyPalletModalComponent } from './containers/work-center-details/components/dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';

@NgModule({
  declarations: [
    WorkCenterListComponent,
    PileTurnerComponent,
    WorkCenterCardComponent,
    MachineLocationModalComponent,
    MountingMaterialComponent,
    MaterialTableComponent,
    WorkCenterDetailsComponent,
    SupplyComponent,
    ProductionOrderListComponent,
    ProductionOrderComponent,
    SelectedPalletComponent,
    TileComponent,
    PileTurnerDismountButtonComponent,
    PalletCounterComponent,
    CreatingDummyModalComponent,
    PalletListModalComponent,
    ReplaceDummyModalComponent,
    DismountContainerModalComponent,
    PalletListComponent,
    MountedPalletTableComponent,
    DismountDummyPalletModalComponent
  ],
  imports: [SharedModule],
  providers: [PileTurnerService, SupplyService, DecimalPipe]
})
export class PileTurnerModule {}
