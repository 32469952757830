<div class="supply">
  <div class="supply__scan-container">
    <lib-scan-container
      [buttonLabel]="'PILE_TURNER.SCAN_SUBMIT' | translate"
      [scanPlaceholderText]="'PILE_TURNER.SCAN_PLACEHOLDER' | translate"
      (mountButtonClicked)="selectPallet($event)"
      [validationChanges$]="scanValidationChanges$"
      [selectInputOnEnter]="true"
      [capsLockWarningMessage]="'PILE_TURNER.CAPSLOCK_ON_WARNING' | translate"
    ></lib-scan-container>
  </div>
  <div class="supply__pallet-indicator">
    <lib-pallet-indicator [title]="title" [palletCount]="containerCount" (clicked)="openMachineLocationModal()"></lib-pallet-indicator>
  </div>
</div>
