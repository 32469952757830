import { Component, Input } from '@angular/core';
import { PileTurnerWorkCenter } from 'chronos-core-client';

@Component({
  selector: 'pt-work-center-card',
  templateUrl: './work-center-card.component.html',
  styleUrls: ['./work-center-card.component.scss']
})
export class WorkCenterCardComponent {
  @Input() public workCenter?: PileTurnerWorkCenter;
  @Input() public isCardSizeReduced = false;
  @Input() public isSelected = false;
  @Input() public isAvailable = true;

  public getKpiControlStatus(): string {
    if (this.workCenter) {
      if (this.workCenter.mounted.containerCount === 1) {
        return 'warning';
      }

      if (this.workCenter.mounted.quantity.value < 0) {
        return 'negative';
      }
    }

    return '';
  }
}
