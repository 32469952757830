import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@pt/app.module';
import { environment } from '@pt-env/environment';
import { BASE_URL } from 'chronos-shared';

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0]?.href ?? `${window.location.origin}/`;
}

const providers = [{ provide: BASE_URL, useFactory: getBaseUrl, deps: [] }];

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
  });
