import { Injectable } from '@angular/core';
import { FrontendConfigService } from 'chronos-core-client';
import { AppSettingsService } from 'chronos-shared';

@Injectable({
  providedIn: 'root'
})
export class FrontEndFeaturesInitService {
  constructor(private appSettingsService: AppSettingsService, private frontendConfigService: FrontendConfigService) {}

  public async initializeWorkCenter(): Promise<any> {
    const features = await this.frontendConfigService.getFeatures().toPromise();
    this.appSettingsService.setAppFeatures(features);
  }
}
