import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppInitService } from '@bd/app-init.service';
import { HeaderService, PileTurnerWorkCenterService } from '@pt/core/services';
import { navUrls } from '@pt/shared/utils';
import { ListValue } from 'chronos-shared';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pt-pile-turner-selection',
  templateUrl: './pile-turner-selection.component.html',
  styleUrls: ['./pile-turner-selection.component.scss']
})
export class PileTurnerSelectionComponent implements OnInit {
  public pileTurnerOptions$!: Observable<ListValue[]>;
  public currentWorkCenter?: number;
  public loading = true;

  constructor(
    private pileTurnerWorkCenterService: PileTurnerWorkCenterService,
    private headerService: HeaderService,
    private router: Router,
    private appInitService: AppInitService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.appInitService.loadingIndicatorSubject$.subscribe((val) => {
      const element = document.getElementById('loader');
      if (!element) {
        return;
      }
      if (val) {
        this.renderer.setStyle(element, 'visibility', 'visible');
      } else {
        this.renderer.setStyle(element, 'display', 'none');
      }
    });

    this.pileTurnerOptions$ = this.getPileTurners();
  }

  public onPileTurnerChange(newPileTurnerId: number): void {
    this.pileTurnerWorkCenterService.setPileTurnerId(newPileTurnerId);
    this.router.navigate([navUrls.root]).then();
  }

  public getPileTurners(): Observable<ListValue[]> {
    return this.headerService.getPileTurners().pipe(finalize(() => (this.loading = false)));
  }
}
