import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PrimaryMaterialMounted } from 'chronos-core-client';

@Component({
  selector: 'lib-container-list-item-white-waste',
  templateUrl: './container-list-item-white-waste.component.html',
  styleUrls: ['./container-list-item-white-waste.component.scss']
})
export class ContainerListItemWhiteWasteComponent {
  @Input() public item: PrimaryMaterialMounted;
  @Input() public addButtonLabel = '';
  @Input() public totalLabel = '';

  @Output() public addWhiteWasteClicked = new EventEmitter<[number, number]>();
  @Output() public resetWhiteWasteClicked = new EventEmitter<number>();

  public whiteWaste = 0;

  public addPalletWhiteWaste(): void {
    this.addWhiteWasteClicked.emit([this.item.mountedMaterialId, this.whiteWaste]);
    this.whiteWaste = 0;
  }

  public resetPalletWhiteWaste(): void {
    this.resetWhiteWasteClicked.emit(this.item.mountedMaterialId);
  }
}
