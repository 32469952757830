import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ArticleTableColumns } from '@pt/modules/pile-turner/pile-turner.model';
import { ArticleDescription } from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { ArticleTableComponent } from '../article-table/article-table.component';

@Component({
  selector: 'pt-article-info',
  templateUrl: './article-info.component.html',
  styleUrls: ['./article-info.component.scss']
})
export class ArticleInfoComponent implements OnInit {
  @Input() public article?: ArticleDescription;
  @Input() public alternativeArticles?: ArticleDescription[];
  @Input() public alternativeArticlesToggle = false;
  public columns: ArticleTableColumns[] = [];

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public ngOnInit() {
    this.columns = [
      { field: 'externalArticleId', header: 'DISMOUNT_CONTAINER_FORM.ARTICLE' },
      { field: 'articleName', header: 'DISMOUNT_CONTAINER_FORM.NAME' }
    ];
  }

  public openArticleDialog(): void {
    this.dialogService
      .open(ArticleTableComponent, {
        header: this.translateService.instant('DISMOUNT_CONTAINER_FORM.ARTICLE'),
        data: {
          tableColumns: this.columns,
          tableData: this.alternativeArticles
        }
      })
      .onClose.subscribe();
  }
}
