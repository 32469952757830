import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PileTurnerAssociatedWorkCenterResponse, PileTurnerWorkCenter } from 'chronos-core-client';

@Component({
  selector: 'pt-work-center-list',
  templateUrl: './work-center-list.component.html',
  styleUrls: ['./work-center-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkCenterListComponent {
  @Input() public set selectedPalletWorkCenterId(workCenterId: number) {
    const selectedWorkCenter = this.workCenters.find((workCenter) => workCenter.workCenterId === workCenterId);
    if (selectedWorkCenter) {
      this.onWorkCenterSelect(selectedWorkCenter);
    }
  }
  @Input() public availableWorkCentersIds: PileTurnerAssociatedWorkCenterResponse[] = [];
  @Input() public workCenters: PileTurnerWorkCenter[] = [];
  @Input() public selectedWorkCenter?: PileTurnerWorkCenter;
  @Input() public isCardSizeReduced = false;

  @Output() public workCenterSelected = new EventEmitter<PileTurnerWorkCenter>();

  public onWorkCenterSelect(workCenter: PileTurnerWorkCenter) {
    if (this.isWorkCenterAvailable(workCenter.workCenterId)) {
      this.selectedWorkCenter = workCenter;
      this.workCenterSelected.emit(workCenter);
    }
  }

  public isWorkCenterAvailable(workCenterId: number): boolean {
    if (this.availableWorkCentersIds) {
      return this.availableWorkCentersIds.map((availableWorkCenter) => availableWorkCenter.workCenterId).includes(workCenterId);
    }

    return true;
  }
}
