import { NgModule } from '@angular/core';
import { PileTurnerSelectionComponent } from './containers/pile-turner-selection/pile-turner-selection.component';
import { SharedModule } from '@pt/shared/shared.module';

@NgModule({
  declarations: [PileTurnerSelectionComponent],
  exports: [PileTurnerSelectionComponent],
  imports: [SharedModule]
})
export class PileTurnerSelectionModule {}
