import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-container-list-item',
  templateUrl: './container-list-item.component.html',
  styleUrls: ['./container-list-item.component.scss']
})
export class ContainerListItemComponent {
  @Input() public gridColumnsWidths = 'auto';
}
