<div class="pile-turner-selection">
  <h1>{{ 'PILE_TURNER_SELECTION.SELECT_PILE_TURNER' | translate }}</h1>
  <lib-dropdown
    [placeholder]="'DROPDOWN_DEFAULT_LABEL' | translate"
    [options]="pileTurnerOptions$ | async"
    [(ngModel)]="currentWorkCenter"
    (ngModelChange)="onPileTurnerChange($event)"
    [loading]="loading"
  ></lib-dropdown>
</div>
