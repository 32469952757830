<div *ngIf="productionOrder" class="order-card">
  <div class="order-card__panel">
    <div class="status-line">
      <div>
        <lib-status-flag
          *ngIf="productionOrder.productionOrderStatus === 'Active'"
          [statusText]="'PRODUCTION_JOB.ACTIVE' | translate"
          [size]="'tiny-plain'"
        ></lib-status-flag>
      </div>
      <div class="order-card__production-order-number">{{ productionOrder.externalProductionOrderId }}</div>
    </div>
    <div class="order-card__kpi">
      <lib-pallet-kpi
        kpiLabel="{{ 'PRODUCTION_JOB.KPI_OPEN' | translate }}"
        [kpiValue]="productionOrder.open.quantity | formatQuantity: false"
        [unit]="productionOrder.open.quantity.unitId"
        [emphasizeText]="true"
        [leftAligned]="true"
      ></lib-pallet-kpi>
    </div>
  </div>
  <div class="order-card__panel">
    <pt-article-info class="article-description" *ngIf="productionOrder?.article" [article]="productionOrder.article"></pt-article-info>
    <div
      *ngIf="productionOrder.productionOrderStatus === 'Active'"
      class="dummy_link"
      (click)="openDummyPalletModal(productionOrder.article.id)"
    >
      <i class="pi pi-plus-circle dummy_link__icon"></i>
      {{ 'MOUNTING.DUMMY' | translate }}
    </div>
  </div>
</div>
