import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { navUrls } from '@pt/shared/utils';
import { PileTurnerSelectionComponent } from './modules/pile-turner-selection/containers/pile-turner-selection/pile-turner-selection.component';
import { authorizeGuard } from 'chronos-shared';

const routes: Routes = [{ path: navUrls.pileTurnerSelection, component: PileTurnerSelectionComponent, canActivate: [authorizeGuard] }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
