import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-container-list-columns',
  templateUrl: './container-list-columns.component.html',
  styleUrls: ['./container-list-columns.component.scss']
})
export class ContainerListColumnsComponent {
  @Input() public gridColumnsWidths = 'auto';
}
