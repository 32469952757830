import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PileTurnerService, RelatedWorkCenter } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class PileTurnerListDsService {
  constructor(private pileTurnerService: PileTurnerService) {}

  public getPileTurners(): Observable<RelatedWorkCenter[]> {
    return this.pileTurnerService.getPileTurnerWorkCenterList();
  }
}
