import { Component, OnDestroy, OnInit } from '@angular/core';
import { PileTurnerService } from '@pt/modules/pile-turner/services/pile-turner/pile-turner.service';
import { PileTurnerScanContainerResponse, PileTurnerWorkCenter } from 'chronos-core-client';
import { map } from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'pt-pile-turner',
  templateUrl: './pile-turner.component.html',
  styleUrls: ['./pile-turner.component.scss']
})
export class PileTurnerComponent implements OnInit, OnDestroy {
  public selectedPallet?: PileTurnerScanContainerResponse;
  public workCenters$ = this.pileTurnerService.getWorkCenters();
  public machineLocationContainerCount$ = this.pileTurnerService.getMachineLocationContainerCount();
  public selectedWorkCenter$ = this.pileTurnerService.getActiveWorkCenter();
  public updatedWorkCenter$ = combineLatest([this.selectedWorkCenter$, this.workCenters$]).pipe(
    map(([workCenter, workCenters]) => workCenters.find((wc) => wc.workCenterId === workCenter?.workCenterId))
  );

  private subscriptions = new Subscription();

  constructor(private pileTurnerService: PileTurnerService) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.pileTurnerService.getFirstWorkCenter().subscribe((workCenter) => {
        this.pileTurnerService.setActiveWorkCenter(workCenter);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public setSelectedPallet(palletInfo: PileTurnerScanContainerResponse): void {
    this.selectedPallet = palletInfo;
  }

  public isCardSizeReduced(workCenters: PileTurnerWorkCenter[]): boolean {
    return workCenters.length > 4;
  }

  public onWorkCenterSelect(workCenter: PileTurnerWorkCenter): void {
    this.pileTurnerService.setActiveWorkCenter(workCenter);
  }
}
