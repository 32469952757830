import { Injectable } from '@angular/core';
import { PileTurnerDsService, WorkCenterDsService } from '@pt/core/data-services';
import { first, map, share, switchMap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { PileTurner, PileTurnerWorkCenter } from 'chronos-core-client';
import { PileTurnerWorkCenterService } from '@pt/core/services';

@Injectable()
export class PileTurnerService {
  public pileTurnerInfo$: Observable<PileTurner>;
  public isSortingMachineSubject = new BehaviorSubject<boolean>(null);
  public isSortingMachineSubject$: Observable<boolean> = this.isSortingMachineSubject.asObservable();

  constructor(
    private pileTurnerDsService: PileTurnerDsService,
    private workCenterDsService: WorkCenterDsService,
    private pileTurnerWorkCenterService: PileTurnerWorkCenterService
  ) {
    this.pileTurnerInfo$ = this.pileTurnerWorkCenterService.pileTurnerId$?.pipe(
      switchMap((pileTurnerId) => this.pileTurnerDsService.getPileTurnerInfo(pileTurnerId)),
      share()
    );
  }

  public getWorkCenters(): Observable<PileTurnerWorkCenter[]> {
    return this.pileTurnerInfo$.pipe(map((pileTurner) => pileTurner.pileTurnerWorkCenter));
  }

  public getMachineLocationContainerCount(): Observable<number> {
    return this.pileTurnerInfo$.pipe(map((pileTurner) => pileTurner.machineLocationContainerCount));
  }

  public setActiveWorkCenter(workCenter: PileTurnerWorkCenter) {
    this.workCenterDsService.setActiveWorkCenter(workCenter);
  }

  public getActiveWorkCenter(): Observable<PileTurnerWorkCenter | undefined> {
    return this.workCenterDsService.getActiveWorkCenter();
  }

  public getFirstWorkCenter(): Observable<PileTurnerWorkCenter> {
    return this.getWorkCenters().pipe(
      first(),
      map((workCenters) => workCenters[0])
    );
  }
}
