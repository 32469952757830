import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MachineLocationModalComponent } from './components/machine-location-modal/machine-location-modal.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { SupplyService } from '@pt/modules/pile-turner/services/supply/supply.service';
import { DialogService } from 'primeng/dynamicdialog';
import { PileTurnerScanContainerResponse } from 'chronos-core-client';

@Component({
  selector: 'pt-supply',
  templateUrl: './supply.component.html',
  styleUrls: ['./supply.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplyComponent implements OnDestroy {
  @Input() public containerCount = 0;
  @Input() public title = '';

  @Output() public selectedPalletChanged = new EventEmitter<PileTurnerScanContainerResponse>();

  private scanValidationSubject = new Subject<boolean>();
  public scanValidationChanges$: Observable<boolean> = this.scanValidationSubject.asObservable();

  private subscriptions = new Subscription();

  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef,
    private supplyService: SupplyService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public openMachineLocationModal(): void {
    const modal = this.dialogService.open(MachineLocationModalComponent, {
      header: this.translateService.instant('PILE_TURNER.MACHINE_LOCATION'),
      data: { isMaterialQuantityRequired: false }
    });

    this.subscriptions.add(
      modal.onClose.subscribe((response) => {
        if (response) {
          this.selectedPalletChanged.emit(response.selectedPallet);
        }
      })
    );
  }

  public selectPallet(sscc: string): void {
    this.supplyService.scanPallet(sscc).subscribe(
      (pallet) => {
        this.emitSelectedPallet(pallet);
      },
      () => {
        this.riseValidationError();
      }
    );
  }

  private emitSelectedPallet(pallet: PileTurnerScanContainerResponse): void {
    this.scanValidationSubject.next(true);
    this.selectedPalletChanged.emit(pallet);
  }

  private riseValidationError(): void {
    this.scanValidationSubject.next(false);
    this.changeDetector.detectChanges();
  }
}
