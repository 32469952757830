<div *ngIf="selectedPallet && workCenterSelected; else noSelectedPallet" class="selected-pallet">
  <div class="pallet-info">
    <div class="pallet-info__number">
      <lib-pallet-number [palletNumber]="1"></lib-pallet-number>
    </div>
    <div class="pallet-info__space"></div>
    <div class="pallet-info__kpi">
      <lib-pallet-kpi
        kpiLabel="{{ 'PILE_TURNER.SSCC' | translate }}"
        [kpiValue]="selectedPallet.ssccCode || ''"
        [compact]="true"
        [showInfoIcon]="true"
        [isSsccCodeShort]="true"
      ></lib-pallet-kpi>
    </div>
    <div class="pallet-info__kpi">
      <lib-pallet-kpi
        kpiLabel="{{ 'PILE_TURNER.MOUNTED' | translate }}"
        [kpiValue]="selectedPallet.goodQuantity | formatQuantity: false"
        [compact]="true"
      ></lib-pallet-kpi>
    </div>
  </div>
  <div class="white-waste">
    <div class="white-waste__label">{{ 'PILE_TURNER.WHITE_WASTE' | translate }}</div>
    <lib-input-number
      [(ngModel)]="whiteWaste"
      class="white-waste__value"
      (touched)="onWhiteWasteBlur()"
      [inputStyle]="{ width: '75px', textAlign: 'right', padding: '10px' }"
      [min]="0"
    ></lib-input-number>
  </div>
  <lib-modal-footer
    [whenLoadingTopic]="LOADING_TOPIC"
    [submitLabel]="'MODAL_FOOTER.MOUNT'"
    (submitClicked)="mountPallet()"
    (declineClicked)="cancelPalletMounting()"
  ></lib-modal-footer>
</div>

<ng-template #noSelectedPallet>
  <div class="no-pallet"></div>
</ng-template>
