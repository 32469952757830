import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { LanguageService } from 'chronos-shared';

@Component({
  selector: 'pt-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public title = 'chronos-pile-turner';

  constructor(private translate: TranslateService, private languageService: LanguageService) {
    this.setTranslationLanguage();
  }

  private setTranslationLanguage() {
    this.languageService.initCurrentDefaultLanguage(environment.defaultLanguage);
    this.languageService.language$.subscribe((lang) => {
      this.translate.setDefaultLang('en');
      this.translate.use(lang);

      if (lang === 'dbg') {
        this.translate.setDefaultLang(lang);
      }
    });
  }
}
