import { Component, OnInit } from '@angular/core';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import { Dummy, DummyMapper, LoadingNotificationService } from 'chronos-shared';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { notificationTopic } from '@pt/shared/utils';
import { finalize } from 'rxjs/operators';
import { VirtualContainerReason, CreatePrimaryVirtualContainerData } from 'chronos-core-client';

@Component({
  selector: 'pt-creating-dummy-modal',
  templateUrl: './creating-dummy-modal.component.html',
  styleUrls: ['./creating-dummy-modal.component.scss']
})
export class CreatingDummyModalComponent implements OnInit {
  public reasonsOptions$!: Observable<VirtualContainerReason[]>;
  public dummyPallet?: Dummy;
  public virtualContainerDetails$: Observable<CreatePrimaryVirtualContainerData>;
  private readonly LOWEST_MOUNTING_QUANTITY = 1;
  private readonly HIGHEST_MOUNTING_QUANTITY = 50000;
  public readonly LOADING_TOPIC = notificationTopic.modalCreateDummy;

  private workCenterId?: number;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private workCenterDetailsService: WorkCenterDetailsService
  ) {}

  public ngOnInit(): void {
    this.reasonsOptions$ = this.workCenterDetailsService.getVirtualContainerReasons();

    this.dummyPallet = {
      ...this.config.data?.dummyPallet,
      lowestMountingQuantity: this.LOWEST_MOUNTING_QUANTITY,
      highestMountingQuantity: this.HIGHEST_MOUNTING_QUANTITY
    } as Dummy;
    this.workCenterId = this.config.data.workCenterId;

    this.virtualContainerDetails$ = this.workCenterDetailsService.getPrimaryVirtualContainerCreationData(
      this.dummyPallet.productionOrderId
    );
  }

  public addNewVirtualContainer(dummyPallet: Dummy): void {
    if (this.workCenterId) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.workCenterDetailsService
        .addVirtualContainer(this.workCenterId, DummyMapper.toVirtualContainerEntry(dummyPallet))
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          this.ref.close(true);
        });
    } else {
      throw new Error(`CreatingDummyModalComponent.addNewVirtualContainer: workCenterId = ${typeof this.workCenterId}`);
    }
  }
}
