import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleInfoComponent, ArticleTableComponent } from '@pt/shared/components';
import { ChronosSharedModule } from 'chronos-shared';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { KeyFilterModule } from 'primeng/keyfilter';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  declarations: [ArticleInfoComponent, ArticleTableComponent],
  imports: [
    // Angular Modules
    CommonModule,
    FormsModule,

    TranslateModule.forChild(),

    // PrimeNg Modules
    DropdownModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    KeyFilterModule,
    CheckboxModule,
    SelectButtonModule,
    TreeTableModule,

    // 3rd party Modules
    ChronosSharedModule
  ],
  exports: [
    // Angular Modules
    CommonModule,
    FormsModule,

    TranslateModule,

    // PrimeNg Modules
    DropdownModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    KeyFilterModule,
    CheckboxModule,
    SelectButtonModule,
    TreeTableModule,

    // 3rd party Modules
    ChronosSharedModule,

    // Shared Components
    ArticleInfoComponent
  ],
  providers: [DialogService]
})
export class SharedModule {}
