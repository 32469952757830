import { ProductType, ArticleClassification } from 'chronos-core-client';

export enum IdentificationCodeType {
  None = 'None',
  SsccCode = 'SsccCode',
  InternalBatchId = 'InternalBatchId',
  ExternalSerialId = 'ExternalSerialId'
}

export interface SecondaryMaterialScanResponse {
  scannedSecondaryIdentificationCodeType: IdentificationCodeType;
  scannedValue: string;
  containerId: number;
  productType: ProductType;
  articleClassification: ArticleClassification;
}

export interface ArticleTableDataRow {
  articleName: string;
  productType: ProductType;
  articleClassification: ArticleClassification;
  bomUnitId: string;
  configurationName?: string;
  externalArticleId: string;
  externalConfigurationId?: string;
  grammage?: number;
  id: number;
  inventoryUnitId: string;
  labelAutoPrint: boolean;
  thickness?: number;
}

export interface ArticleTableColumns {
  field: string;
  header: string;
  style?: string;
  width?: number;
}

export enum WorkCenterDetailsView {
  OrderView,
  MountedPalletsView
}

export enum MountedPalletItemType {
  MaterialBlock,
  MaterialMounted
}
