import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { WorkCenterState, WorkCenterStore } from './work-center.store';

@Injectable({ providedIn: 'root' })
export class WorkCenterQuery extends Query<WorkCenterState> {
  public activeWorkCenter$ = this.select((state) => state.selectedWorkCenter);

  constructor(protected workCenterStore: WorkCenterStore) {
    super(workCenterStore);
  }
}
