import { Component } from '@angular/core';
import { ArticleTableColumns, ArticleTableDataRow } from '@pt/modules/pile-turner/pile-turner.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { notificationTopic } from '@app/shared/utils';

@Component({
  selector: 'pt-article-table',
  templateUrl: './article-table.component.html',
  styleUrls: ['./article-table.component.scss']
})
export class ArticleTableComponent {
  public tableColumns: ArticleTableColumns[] = [];
  public tableData: ArticleTableDataRow[] = [];
  public readonly LOADING_TOPIC = notificationTopic.modalMachineLocation;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {
    this.tableColumns = this.config.data?.tableColumns;
    this.tableData = this.config.data?.tableData;
  }

  public onCloseModal(): void {
    this.ref.close();
  }
}
