import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { MountedMaterialStatus, PrimaryMaterialMounted } from 'chronos-core-client';
import { DummyContainerDirective, EmptyContainerDirective, ItemContainerDirective, MaterialBlockDirective } from '../../../directives';

@Component({
  selector: 'lib-container-list',
  templateUrl: './container-list.component.html',
  styleUrls: ['./container-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerListComponent {
  @Input() public mountedMaterials: PrimaryMaterialMounted[] = [];

  @ContentChild(ItemContainerDirective, { read: TemplateRef }) public itemTemplate;
  @ContentChild(DummyContainerDirective, { read: TemplateRef }) public dummyTemplate;
  @ContentChild(EmptyContainerDirective, { read: TemplateRef }) public emptyTemplate;
  @ContentChild(MaterialBlockDirective, { read: TemplateRef }) public materialBlock;

  public readonly MountedMaterialStatus = MountedMaterialStatus;

  public trackByFunction(index: number, mountedMaterial: PrimaryMaterialMounted): number {
    return mountedMaterial.mountedMaterialId;
  }
}
