import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PileTurnerListDsService } from '@pt/core/data-services/pile-turner-list-ds';
import { PileTurnerWorkCenterService } from '@pt/core/services';
import { navUrls } from '@pt/shared/utils';
import { RelatedWorkCenter } from 'chronos-core-client';
import { of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { AuthorizeService } from 'chronos-shared';

export const workCenterGuard = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const WORK_CENTER_QUERY = 'wcid';
  const externalWorkCenterId = route.queryParamMap.get(WORK_CENTER_QUERY);
  const pileTurnerListDsService: PileTurnerListDsService = inject(PileTurnerListDsService);
  const pileTurnerWorkCenterService: PileTurnerWorkCenterService = inject(PileTurnerWorkCenterService);
  const authorizeService: AuthorizeService = inject(AuthorizeService);
  const router: Router = inject(Router);

  if (externalWorkCenterId) {
    return pileTurnerListDsService.getPileTurners().pipe(
      map((workCenters) => getAvailableWorkCenter(workCenters, externalWorkCenterId)),
      map((workCenter) => redirectToWorkCenter(workCenter, state, pileTurnerWorkCenterService, router))
    );
  } else if (!pileTurnerWorkCenterService.getPileTurnerId()) {
    return authorizeService.isAuthenticated().pipe(
      switchMap((isAuthenticated) => {
        if (isAuthenticated) {
          return pileTurnerWorkCenterService
            .initializePileTurnerId()
            .pipe(
              switchMap((isInitialized) =>
                isInitialized ? of(router.createUrlTree([navUrls.root])) : of(router.createUrlTree([navUrls.pileTurnerSelection]))
              )
            );
        } else {
          return of(true);
        }
      }),
      take(1)
    );
  } else {
    return of(true);
  }
};

const getAvailableWorkCenter = (workCenters: RelatedWorkCenter[], externalId: string): RelatedWorkCenter | undefined =>
  workCenters.find((workCenter) => workCenter.externalWorkCenterId === externalId);

const redirectToWorkCenter = (
  workCenter: RelatedWorkCenter | undefined,
  state: RouterStateSnapshot,
  pileTurnerWorkCenterService: PileTurnerWorkCenterService,
  router: Router
): UrlTree => {
  if (workCenter) {
    const path = state.url.split('?')[0];
    pileTurnerWorkCenterService.setPileTurnerId(workCenter.id);
    return router.createUrlTree([path]);
  } else {
    return router.createUrlTree([navUrls.pileTurnerSelection]);
  }
};
