import { Injectable } from '@angular/core';
import {
  MaterialAvailableAtLocation,
  MountByType,
  MountPrimaryContainerEntry,
  MountPrimaryContainerResponse,
  PileTurnerPalletList,
  PileTurnerScanContainerResponse,
  PrimaryMaterialBlock,
  PrimaryMaterialMounted,
  Quantity,
  CreateAndMountVirtualPrimaryContainerEntry,
  VirtualContainerReason,
  CreatePrimaryVirtualContainerData
} from 'chronos-core-client';
import { PileTurnerDsService } from '@pt/core/data-services';
import { Observable, of } from 'rxjs';
import { TreeNode } from 'primeng/api';
import { map } from 'rxjs/operators';
import { MountedPalletItemType } from '@pt/modules/pile-turner/pile-turner.model';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDetailsService {
  constructor(private pileTurnerDsService: PileTurnerDsService) {}

  public mountPallet(
    workCenterId: number,
    pallet: PileTurnerScanContainerResponse,
    whiteWaste: Quantity
  ): Observable<MountPrimaryContainerResponse> {
    const productionOrderId = pallet.allowedWorkCenterIds.find(
      (workCenterInfo) => workCenterInfo.workCenterId === workCenterId
    )?.productionOrderId;

    const primaryContainerEntry: MountPrimaryContainerEntry = {
      identificationCode: pallet.ssccCode,
      containerId: pallet.containerId,
      whiteWasteQuantity: whiteWaste,
      mountByType: MountByType.MATERIAL_BLOCK,
      productionOrderId
    };
    return this.pileTurnerDsService.mountPallet(workCenterId, primaryContainerEntry);
  }

  public getVirtualContainerReasons(): Observable<VirtualContainerReason[]> {
    return this.pileTurnerDsService.getVirtualContainerReasons();
  }

  public getPrimaryVirtualContainerCreationData(productionOrderId: number): Observable<CreatePrimaryVirtualContainerData> {
    return this.pileTurnerDsService.getPrimaryVirtualContainerCreationData(productionOrderId);
  }

  public addVirtualContainer(workCenterId: number, virtualContainer: CreateAndMountVirtualPrimaryContainerEntry): Observable<null> {
    return this.pileTurnerDsService.addVirtualContainer(workCenterId, virtualContainer);
  }

  public getPalletList(workCenterId: number): Observable<PileTurnerPalletList> {
    return this.pileTurnerDsService.getPalletList(workCenterId);
  }

  public addContainerWhiteWaste(mountedMaterialId: number, workCenterId: number, whiteWaste: Quantity): Observable<null> {
    return this.pileTurnerDsService.addContainerWhiteWaste(mountedMaterialId, workCenterId, whiteWaste);
  }

  public removeContainerWhiteWaste(mountedMaterialId: number, workCenterId: number): Observable<null> {
    return this.pileTurnerDsService.removeContainerWhiteWaste(mountedMaterialId, workCenterId);
  }

  public getAvailableContainers(workCenterId: number): Observable<MaterialAvailableAtLocation> {
    return this.pileTurnerDsService.getMaterialAvailableAtMachine(workCenterId);
  }

  public replaceContainer(
    mountedMaterialId: number,
    workCenterId: number,
    virtualContainerId: number,
    newContainerId: number
  ): Observable<null> {
    return this.pileTurnerDsService.replaceContainer(mountedMaterialId, workCenterId, virtualContainerId, newContainerId);
  }

  public dismountContainer(
    containerId: number,
    workCenterId: number,
    mountedMaterialId: number,
    remainingQuantity: number,
    isLabelPrinted: boolean
  ): Observable<null> {
    return this.pileTurnerDsService.dismountContainer(containerId, workCenterId, mountedMaterialId, remainingQuantity, isLabelPrinted);
  }

  public getNextDismountableContainer(workCenterId: number): Observable<PrimaryMaterialMounted> {
    return this.pileTurnerDsService.nextContainerForDismount(workCenterId);
  }

  public getMountedMaterialsTree(workCenterId: number): Observable<TreeNode[]> {
    if (workCenterId) {
      return this.pileTurnerDsService
        .getMountedMaterials(workCenterId)
        .pipe(map((materialBlocks) => this.mapMaterialBlocksToTreeNodes(materialBlocks)));
    } else {
      return of([]);
    }
  }

  private mapMaterialBlocksToTreeNodes(materialBlocks: PrimaryMaterialBlock[]): TreeNode[] {
    return materialBlocks.map((item) => ({
      data: {
        ...item,
        itemType: MountedPalletItemType.MaterialBlock,
        mountedPalletsCount: item.mountedMaterials.length,
        mountedQuantitiesSum: this.calculateMountedQuantities(item.mountedMaterials),
        consumedQuantitiesSum: this.calculateConsumedQuantities(item.mountedMaterials)
      },
      children: this.mapMountedMaterialsToTreeNodes(item.mountedMaterials, item.productionOrderInfo?.externalProductionOrderId)
    }));
  }

  private mapMountedMaterialsToTreeNodes(mountedMaterials: PrimaryMaterialMounted[], externalProductionOrderId?: string): TreeNode[] {
    return mountedMaterials.map((item) => ({
      data: { ...item, externalProductionOrderId, itemType: MountedPalletItemType.MaterialMounted }
    }));
  }

  private calculateMountedQuantities(mountedMaterials: PrimaryMaterialMounted[]): Quantity {
    return this.calculateQuantities(mountedMaterials.map((material) => material.mountedQuantity));
  }

  private calculateConsumedQuantities(mountedMaterials: PrimaryMaterialMounted[]): Quantity {
    return this.calculateQuantities(mountedMaterials.map((material) => material.consumedQuantity));
  }

  private calculateQuantities(quantities: Quantity[]): Quantity {
    let unitId: string | undefined;
    let value = 0;
    if (quantities.length > 0) {
      value = quantities.map((quantity) => quantity.value).reduce((a, b) => a + b);
      unitId = quantities[0].unitId;
    }
    return { value, unitId };
  }
}
