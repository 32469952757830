<section class="pile-turner page-padding">
  <div class="pile-turner__left-container">
    <pt-supply
      [containerCount]="machineLocationContainerCount$ | async"
      [title]="'PILE_TURNER.MACHINE_LOCATION' | translate"
      (selectedPalletChanged)="setSelectedPallet($event)"
    ></pt-supply>
    <ng-container *ngIf="workCenters$ | async as workCenters">
      <pt-work-center-list
        [workCenters]="workCenters"
        [selectedWorkCenter]="selectedWorkCenter$ | async"
        (workCenterSelected)="onWorkCenterSelect($event)"
        [isCardSizeReduced]="isCardSizeReduced(workCenters)"
        [selectedPalletWorkCenterId]="selectedPallet?.preferredWorkCenterId"
        [availableWorkCentersIds]="selectedPallet?.allowedWorkCenterIds"
      ></pt-work-center-list>
    </ng-container>
  </div>
  <div class="pile-turner__right-container">
    <pt-work-center-details
      [workCenter]="updatedWorkCenter$ | async"
      [pallet]="selectedPallet"
      (selectedPalletChanged)="setSelectedPallet($event)"
    ></pt-work-center-details>
  </div>
</section>
