<div class="container-list">
  <div class="container-list__containers">
    <ng-container *ngIf="mountedMaterials?.length > 0; else emptyList">
      <div class="container-list__item" *ngFor="let container of mountedMaterials; trackBy: trackByFunction">
        <ng-container
          [ngTemplateOutlet]="container.container.isVirtualContainer ? dummyTemplate : itemTemplate"
          [ngTemplateOutletContext]="{ $implicit: container }"
        ></ng-container>
      </div>
    </ng-container>
    <ng-template #emptyList>
      <ng-container [ngTemplateOutlet]="emptyTemplate"></ng-container>
    </ng-template>
  </div>
  <div class="container-list__material-block">
    <ng-container [ngTemplateOutlet]="materialBlock"></ng-container>
  </div>
</div>
