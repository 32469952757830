<div
  *ngIf="workCenter"
  class="work-center-card"
  [ngClass]="{ 'work-center-card--reduced': isCardSizeReduced, 'work-center-card--selected': isSelected }"
>
  <div class="work-center-card__pallets">
    <pt-pallet-counter
      [palletCount]="workCenter.mounted?.containerCount"
      [classification]="workCenter.article?.classification"
    ></pt-pallet-counter>
  </div>
  <div class="work-center-card__info">
    <div class="work-center-title">{{ workCenter.workCenter.externalWorkCenterId }} {{ workCenter.workCenter.workCenterName }}</div>
    <div class="work-center-card__articles">
      <pt-article-info
        *ngIf="workCenter.article"
        [article]="workCenter.article"
        [alternativeArticles]="workCenter?.alternativeArticles"
        [alternativeArticlesToggle]="true"
      ></pt-article-info>
    </div>
  </div>
  <div class="work-center-card__action" *ngIf="workCenter.toBeDismounted?.containerCount as dismountCount">
    <lib-dismount-button *ngIf="dismountCount" [dismountableCount]="dismountCount"></lib-dismount-button>
  </div>
  <div class="work-center-card__kpis">
    <div class="work-center-card__kpi-item">
      <lib-kpi-control
        [title]="'PILE_TURNER.MOUNTED' | translate"
        [kpiStatus]="getKpiControlStatus()"
        [value]="workCenter.mounted.quantity | formatQuantity : false"
        [unit]="workCenter.mounted.quantity.unitId"
      ></lib-kpi-control>
    </div>
    <div class="work-center-card__kpi-item">
      <lib-kpi-control
        [title]="'PILE_TURNER.OPEN' | translate"
        [value]="workCenter.open.quantity | formatQuantity : false"
        [unit]="workCenter.open.quantity.unitId"
      ></lib-kpi-control>
    </div>
  </div>
  <div *ngIf="!isAvailable" class="work-center-card__disabled-overlay"></div>
</div>
