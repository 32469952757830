import { Component, Input } from '@angular/core';

@Component({
  selector: 'pt-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent {
  @Input() public label = '';
  @Input() public value = '';
  @Input() public unit = '';
}
