<lib-creating-dummy-form
  *ngIf="virtualContainerDetails$ | async as details"
  [reasonsOptions]="details.virtualContainerReasons"
  [dummy]="dummyPallet"
  (createNewDummyEvent)="addNewVirtualContainer($event)"
  [inputPlaceholder]="'MOUNTING.PLEASE_ENTER_ID' | translate"
  [mountedLabel]="'MOUNTING.MOUNTED' | translate"
  [reasonsLabel]="'MOUNTING.REASON' | translate"
  [infoLabel]="'MOUNTING.INFO' | translate"
  [mountButtonLabel]="'MODAL_FOOTER.MOUNT'"
  [reasonsDefaultValue]="'DROPDOWN_DEFAULT_LABEL' | translate"
  [loadingTopic]="LOADING_TOPIC"
  [askForProductionOrder]="details.askForProductionOrder"
  [productionOrderCodeLabel]="'MOUNTING.PRODUCTIONORDERCODE_LABEL' | translate"
></lib-creating-dummy-form>
