<div class="pallet-counter">
  <div
    class="pallet-counter__value"
    [ngClass]="{
      'pallet-counter__value--error': pallets < LAST_PALLET,
      'pallet-counter__value--warning': pallets === LAST_PALLET,
      'pallet-counter__value--normal': pallets > LAST_PALLET
    }"
  >
    {{ pallets }}
  </div>
  <i
    *ngIf="palletType === 'pallet'"
    class="pallet-counter__pallet icon-pallet"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 3 }"
  ></i>
  <i
    *ngIf="palletType === 'pallet'"
    class="pallet-counter__pallet icon-pallet"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 2 }"
  ></i>
  <i
    *ngIf="palletType === 'pallet'"
    class="pallet-counter__pallet icon-pallet"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 1 }"
  ></i>

  <i
    *ngIf="palletType === 'roll'"
    class="pallet-counter__pallet icon-roll"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 3 }"
  ></i>
  <i
    *ngIf="palletType === 'roll'"
    class="pallet-counter__pallet icon-roll"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 2 }"
  ></i>
  <i
    *ngIf="palletType === 'roll'"
    class="pallet-counter__pallet icon-roll"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 1 }"
  ></i>

  <i
    *ngIf="palletType === 'container'"
    class="pallet-counter__pallet icon-container"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 3 }"
  ></i>
  <i
    *ngIf="palletType === 'container'"
    class="pallet-counter__pallet icon-container"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 2 }"
  ></i>
  <i
    *ngIf="palletType === 'container'"
    class="pallet-counter__pallet icon-container"
    [ngClass]="{ 'pallet-counter__pallet--empty': pallets < 1 }"
  ></i>
</div>
