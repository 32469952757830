import { Component, Input, OnChanges, OnInit, SimpleChange } from '@angular/core';

@Component({
  selector: 'pt-pallet-counter',
  templateUrl: './pallet-counter.component.html',
  styleUrls: ['./pallet-counter.component.scss']
})
export class PalletCounterComponent implements OnInit, OnChanges {
  @Input() public palletCount: number;
  @Input() public classification: string;

  public readonly LAST_PALLET = 1;
  public pallets = 0;
  public palletType: string;

  public ngOnInit() {
    this.palletType = this.getPalletType();
    this.pallets = this.pallets = this.palletCount || 0;
  }

  public ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    if (changes.classification && changes.classification.currentValue !== changes.classification.previousValue) {
      this.palletType = this.getPalletType();
    }
    if (changes.palletCount && changes.palletCount.currentValue !== changes.palletCount.previousValue) {
      this.pallets = this.pallets = this.palletCount || 0;
    }
  }

  public getPalletType() {
    if (this.classification === 'Reel') {
      return 'roll';
    } else if (this.classification === 'Sheet' || this.classification === 'Product') {
      return 'pallet';
    } else {
      return 'container';
    }
  }
}
