import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { PileTurnerWorkCenter } from 'chronos-core-client';

export interface WorkCenterState {
  selectedWorkCenter?: PileTurnerWorkCenter;
}

export function createInitialState(): WorkCenterState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'work-center', idKey: 'workCenterId' })
export class WorkCenterStore extends Store<WorkCenterState> {
  constructor() {
    super(createInitialState());
  }
}
