import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import { SecondaryMaterialScanResponse } from '@pt/modules/pile-turner/pile-turner.model';
import { ArticleDescription, MaterialAvailableAtLocationItem } from 'chronos-core-client';
import { TranslateService } from '@ngx-translate/core';
import { MaterialTableColumns, MaterialTableDataRow } from 'projects/chronos-shared/src/lib/models/modal-main-material-table';

@Component({
  selector: 'pt-mounting-material',
  templateUrl: './mounting-material.component.html',
  styleUrls: ['./mounting-material.component.scss']
})
export class MountingMaterialComponent implements OnInit {
  @Input() public availableMountingMaterial: MaterialAvailableAtLocationItem[] = [];
  @Input() public billOfMaterialArticleList: ArticleDescription[] = [];
  @Input() public submitButtonLabel = 'MOUNTING.CONSUME';
  @Input() public loadingTopic = '';

  @Output() public mountingMaterialSubmit = new EventEmitter<[MaterialAvailableAtLocationItem | null, number, number]>();

  public readonly MINIMUM_MATERIAL_QUANTITY = 0;

  public warehouseData: MaterialAvailableAtLocationItem[] = [];
  public warehouseFilteredData: MaterialTableDataRow[] = [];
  public selectedMaterial: MaterialAvailableAtLocationItem | null = null;
  public selectedMaterialQuantity = this.MINIMUM_MATERIAL_QUANTITY;
  public selectedMaterialQuantityUnitId?: string;
  public columns: MaterialTableColumns[] = [];
  public dropDownOptions: any[] = [];
  public selectedOption?: number;
  public preselectedArticle?: ArticleDescription;
  public preselectedMaterialFilter?: SecondaryMaterialScanResponse;
  public isMaterialQuantityRequired = true;
  public materialBlockId = 0;
  public isDropDownOptionDisabled = false;

  constructor(private ref: DynamicDialogRef, private conf: DynamicDialogConfig, private translateService: TranslateService) {}

  public ngOnInit(): void {
    if (!R.isNil(this.conf.data.articleForConsumption)) {
      this.selectedOption = this.conf.data.articleForConsumption.id;
      this.preselectedArticle = this.conf.data.articleForConsumption;
    }

    if (!R.isNil(this.conf.data.isMaterialQuantityRequired)) {
      this.isMaterialQuantityRequired = this.conf.data.isMaterialQuantityRequired;
    }

    this.preselectedMaterialFilter = this.conf.data.filterFromScan;
    this.materialBlockId = this.conf.data.materialBlockId;

    this.warehouseData = this.availableMountingMaterial;
    this.filterWarehouseData();

    if (this.billOfMaterialArticleList) {
      if (this.billOfMaterialArticleList.length <= 1) {
        this.isDropDownOptionDisabled = true;
      }
      this.setDropDownOptions();
    }

    this.columns = [
      { field: 'isBlocked', type: 'status', header: 'MOUNTING.STATUS', width: 80 },
      { field: 'externalArticleId', header: 'MOUNTING.ITEM_ID', width: 120 },
      { field: 'identificationCode', header: 'MOUNTING.IDENTIFICATION', type: 'sscc', width: 210 },
      { field: 'internalBatchId', header: 'MOUNTING.BATCH_INTERNAL', width: 150 },
      { field: 'externalBatchId', header: 'MOUNTING.BATCH_EXTERNAL', width: 155 },
      { field: 'quantity', type: 'quantity', header: 'MOUNTING.QUANTITY', style: 'text-right', width: 115 },
      { field: 'onHandWeight', type: 'quantity', header: 'MOUNTING.ON_HAND', style: 'text-right', width: 115 },
      { field: 'warehouseName', header: 'MOUNTING.WAREHOUSE', width: 140 },
      { field: 'warehouseLocationName', header: 'MOUNTING.LOCATION', width: 120 },
      { field: 'dateTimeAvailable', type: 'datetime', header: 'MOUNTING.MADE_AVAILABLE' }
    ];
  }

  public onSubmitClick(): void {
    this.mountingMaterialSubmit.emit([this.selectedMaterial, this.selectedMaterialQuantity, this.materialBlockId]);
  }

  public onDeclineClick(): void {
    this.ref.close(false);
  }

  public selectedMaterialChange(material: MaterialAvailableAtLocationItem): void {
    this.selectedMaterial = material;
    this.selectedMaterialQuantity = material ? material.bomQuantity.value : this.MINIMUM_MATERIAL_QUANTITY;
    this.selectedMaterialQuantityUnitId = material ? material.bomQuantity.unitId : '';
  }

  public isSubmitEnabled(): boolean {
    return !R.isNil(this.selectedMaterial) && this.isQuantityValid(this.selectedMaterial);
  }

  public filterWarehouseData(): void {
    this.resetSelectedMaterial();

    let filteredData = this.warehouseData;
    if (this.selectedOption) {
      filteredData = this.filterByArticleId(this.selectedOption);
    } else if (this.preselectedMaterialFilter) {
      filteredData = this.filterByMaterialScanResponse(this.preselectedMaterialFilter);
    }

    this.warehouseFilteredData = filteredData.map((filteredDataRow) => ({
      ...filteredDataRow,
      quantity: filteredDataRow.bomQuantity,
      onHandWeight: filteredDataRow.inventoryQuantity,
      externalArticleId: filteredDataRow.article.externalArticleId,
      externalConfigurationId: filteredDataRow.article.externalConfigurationId
    }));
  }

  private filterByArticleId(articleId: number): MaterialAvailableAtLocationItem[] {
    return this.warehouseData.filter((data) => data.article.id === articleId);
  }

  private filterByMaterialScanResponse(materialScanResponse: SecondaryMaterialScanResponse): MaterialAvailableAtLocationItem[] {
    return this.warehouseData.filter((data) => data.identificationCode === materialScanResponse.scannedValue);
  }

  private resetSelectedMaterial(): void {
    this.selectedMaterial = null;
    this.selectedMaterialQuantity = this.MINIMUM_MATERIAL_QUANTITY;
  }

  private isQuantityValid(material: MaterialAvailableAtLocationItem): boolean {
    const isAboveMinimum = this.selectedMaterialQuantity >= this.MINIMUM_MATERIAL_QUANTITY;
    const isBelowMaximum = this.selectedMaterialQuantity <= material.bomQuantity.value;

    return isAboveMinimum && isBelowMaximum;
  }

  private setDropDownOptions(): void {
    let options: any[] = [];
    this.dropDownOptions = [];

    if (!R.isNil(this.billOfMaterialArticleList)) {
      options = R.pipe(
        R.map((article: ArticleDescription) => ({
          label: `${article.externalArticleId} ${article.externalConfigurationId} ${article.articleName}`,
          value: article.id
        })),
        R.uniq
      )(this.billOfMaterialArticleList).concat({
        label: this.translateService.instant('DROPDOWN_ALL_LABEL'),
        value: null
      });
    }

    this.dropDownOptions = options;
  }
}
