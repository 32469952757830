import { Injectable } from '@angular/core';
import { WorkCenterStore } from './work-center.store';
import { PileTurnerWorkCenter } from 'chronos-core-client';

@Injectable({ providedIn: 'root' })
export class WorkCenterService {
  constructor(private workCenterStore: WorkCenterStore) {}

  public setActiveWorkCenter(workCenter: PileTurnerWorkCenter) {
    this.workCenterStore.update({ selectedWorkCenter: workCenter });
  }
}
