import { Component } from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { Observable } from 'rxjs';

export interface MenuItem {
  icon: string;
  tooltip: string;
  link?: (() => string) | string;
  showBadge?: boolean;
  counter?: Observable<number>;
  onClick?: () => void;
  isActiveMatchOptions?: IsActiveMatchOptions;
}

@Component({
  selector: 'pt-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent {
  public readonly menuItems: MenuItem[] = [
    {
      link: '/',
      tooltip: 'MAIN_MENU.PILE_TURNER',
      icon: 'icon-mounting',
      onClick: () => this.router.navigate(['/']),
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }
    },
    {
      tooltip: 'MAIN_MENU.SETTINGS',
      icon: 'icon-settings',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }
    },
    {
      tooltip: 'MAIN_MENU.INFO',
      icon: 'icon-info-solid',
      isActiveMatchOptions: { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' }
    }
  ];

  constructor(private router: Router) {}

  public isMenuItemActive(menuItem: MenuItem): boolean {
    if (!menuItem.link) {
      return false;
    }

    const link = typeof menuItem.link === 'function' ? menuItem.link() : menuItem.link;

    return this.router.isActive(link, menuItem.isActiveMatchOptions);
  }
}
