import { Component, Input } from '@angular/core';

@Component({
  selector: 'pt-dismount-button',
  templateUrl: './pile-turner-dismount-button.component.html',
  styleUrls: ['./pile-turner-dismount-button.component.scss']
})
export class PileTurnerDismountButtonComponent {
  @Input() public dismountableCount = 0;
}
