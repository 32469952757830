import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListValue } from 'chronos-shared';
import { PileTurnerListDsService } from '@pt/core/data-services/pile-turner-list-ds';
import { RelatedWorkCenter } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  constructor(private pileTurnerListDsService: PileTurnerListDsService) {}

  public getPileTurners(): Observable<ListValue[]> {
    return this.pileTurnerListDsService.getPileTurners().pipe(
      map<RelatedWorkCenter[], ListValue[]>((pileTurners) =>
        pileTurners.map(({ id, externalWorkCenterId, name }) => ({ label: `${externalWorkCenterId} ${name}`, value: id }))
      )
    );
  }
}
