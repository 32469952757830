import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MaterialTableColumns, MaterialTableDataRow } from 'projects/chronos-shared/src/lib/models/modal-main-material-table';

@Component({
  selector: 'pt-material-table',
  templateUrl: './material-table.component.html',
  styleUrls: ['./material-table.component.scss']
})
export class MaterialTableComponent {
  @Input() public tableColumns: MaterialTableColumns[] = [];
  @Input() public tableData: MaterialTableDataRow[] = [];
  @Input() public selectedRow: any;

  @Output() public selectedRowChanged = new EventEmitter<MaterialTableDataRow>();

  public emitSelectedRow(): void {
    this.selectedRowChanged.emit(this.selectedRow);
  }
}
