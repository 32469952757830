import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MaterialAvailableAtLocationItem, MaterialAvailableAtLocation } from 'chronos-core-client';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import { finalize } from 'rxjs/operators';
import { notificationTopic } from '@pt/shared/utils';
import { LoadingNotificationService } from 'chronos-shared';

@Component({
  selector: 'pt-replace-dummy-modal',
  templateUrl: './replace-dummy-modal.component.html',
  styleUrls: ['./replace-dummy-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReplaceDummyModalComponent implements OnInit {
  public readonly LOADING_TOPIC = notificationTopic.modalReplaceDummy;
  public availableForMount$: Observable<MaterialAvailableAtLocation>;

  private dummyContainerId?: number;
  private mountedMaterialId?: number;
  private workCenterId?: number;

  constructor(
    private workCenterDetailsService: WorkCenterDetailsService,
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig
  ) {}

  public ngOnInit(): void {
    this.workCenterId = this.config.data?.workCenterId;
    this.dummyContainerId = this.config.data?.dummyContainerId;
    this.mountedMaterialId = this.config.data?.mountedMaterialId;

    if (this.workCenterId) {
      this.availableForMount$ = this.workCenterDetailsService.getAvailableContainers(this.workCenterId);
    } else {
      throw new Error(`ReplaceDummyModalComponent.ngOnInit: workCenterId = ${typeof this.workCenterId}`);
    }
  }

  public onSelectClick([selectedMaterial]: [MaterialAvailableAtLocationItem, number, number]): void {
    if (this.mountedMaterialId && this.workCenterId && this.dummyContainerId) {
      LoadingNotificationService.publish(this.LOADING_TOPIC, true);
      this.workCenterDetailsService
        .replaceContainer(this.mountedMaterialId, this.workCenterId, this.dummyContainerId, selectedMaterial.containerId)
        .pipe(
          finalize(() => {
            LoadingNotificationService.publish(this.LOADING_TOPIC, false);
          })
        )
        .subscribe(() => {
          this.ref.close(true);
        });
    } else {
      throw new Error(
        `ReplaceDummyModalComponent.onSelectClick: workCenterId = ${typeof this.workCenterId}, mountedMaterialId = ${typeof this
          .mountedMaterialId}, dummyContainerId = ${typeof this.dummyContainerId}`
      );
    }
  }
}
