import { Injectable } from '@angular/core';
import { WorkCenterQuery, WorkCenterService } from '@pt/core/global-state';
import { PileTurnerWorkCenter } from 'chronos-core-client';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WorkCenterDsService {
  constructor(private workCenterQuery: WorkCenterQuery, private workCenterService: WorkCenterService) {}

  public setActiveWorkCenter(workCenter: PileTurnerWorkCenter): void {
    this.workCenterService.setActiveWorkCenter(workCenter);
  }

  public getActiveWorkCenter(): Observable<PileTurnerWorkCenter | undefined> {
    return this.workCenterQuery.activeWorkCenter$;
  }
}
