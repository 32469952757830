<div class="work-center-list">
  <pt-work-center-card
    *ngFor="let workCenter of workCenters"
    [workCenter]="workCenter"
    [isCardSizeReduced]="isCardSizeReduced"
    (click)="onWorkCenterSelect(workCenter)"
    [isSelected]="workCenter.workCenterId === selectedWorkCenter?.workCenterId"
    [isAvailable]="isWorkCenterAvailable(workCenter.workCenterId)"
  ></pt-work-center-card>
</div>
