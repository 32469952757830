<div class="mounted-pallet-list">
  <p-treeTable [value]="mountedPalletData">
    <ng-template pTemplate="header">
      <tr class="mounted-pallet-list__header-row">
        <th class="toggle-cell">
          <i
            class="mounted-pallet-list__expand-collapse-icon cursor-clickable"
            [ngClass]="tableExpanded ? 'icon-collapse-all' : 'icon-expand-all'"
            (click)="expandCollapseTable()"
          ></i>
        </th>
        <th class="text-cell">{{ 'MOUNTED_PALLETS.ARTICLE' | translate }}</th>
        <th class="text-cell">{{ 'MOUNTED_PALLETS.ARTICLE_NAME' | translate }}</th>
        <th class="quantity-cell">{{ 'MOUNTED_PALLETS.MOUNTED' | translate }}</th>
        <th class="quantity-cell">{{ 'MOUNTED_PALLETS.CONSUMED' | translate }}</th>
        <th class="action-cell"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
      <tr *ngIf="rowData.itemType === ITEM_TYPE.MaterialBlock" class="mounted-pallet-list__item-row">
        <td class="toggle-cell"><p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler></td>
        <td class="text-cell">
          <div>{{ rowData.article.externalArticleId }}</div>
          <div>{{ rowData.article.externalConfigurationId }}</div>
        </td>
        <td class="text-cell">
          <div>{{ rowData.article.articleName }}</div>
          <div>{{ rowData.article.configurationName }}</div>
        </td>
        <td class="quantity-cell">
          <div>{{ 'MOUNTED_PALLETS.PAL' | translate: { palletsCount: rowData.mountedPalletsCount } }}</div>
          <div>{{ rowData.mountedQuantitiesSum | formatQuantity }}</div>
        </td>
        <td class="quantity-cell">
          <div>{{ rowData.consumedQuantitiesSum | formatQuantity }}</div>
        </td>
        <td class="action-cell"></td>
      </tr>

      <tr
        *ngIf="rowData.itemType === ITEM_TYPE.MaterialMounted"
        class="mounted-pallet-list__item-row second-level-row"
        [ngClass]="{ 'second-level-row__dummy': rowData.container?.isVirtualContainer }"
      >
        <td class="toggle-cell"></td>
        <td class="text-cell">
          {{ rowData.article?.externalArticleId }}
          <br />
          {{ rowData.article?.externalConfigurationId }}
          <br />
          {{ rowData.externalProductionOrderId }}
        </td>
        <td class="text-cell"><span [innerHTML]="rowData.ssccCode | sscc"></span></td>
        <td class="quantity-cell">
          {{ rowData.mountedQuantity | formatQuantity: false }}
          {{ rowData.mountedQuantity.unitId }}
        </td>
        <td class="quantity-cell">
          {{ rowData.consumedQuantity | formatQuantity: false }}
          {{ rowData.consumedQuantity.unitId }}
        </td>
        <td *ngIf="buttonTemplate" class="action-cell">
          <ng-container *ngTemplateOutlet="buttonTemplate; context: { $implicit: rowData, rowNode: rowNode }"></ng-container>
        </td>
      </tr>
    </ng-template>
  </p-treeTable>
</div>
