<div class="active-order">
  <ng-container *ngIf="activeOrder; else noActiveOrder">
    <div class="active-order__title">
      <div class="text__label">{{ 'ACTIVE_ORDER.TITLE' | translate }}</div>
      <div class="text__value">{{ activeOrder.externalProductionOrderId }}</div>
    </div>
    <div class="active-order__product">
      <div>{{ activeOrder.customers[0]?.customerName }}</div>
      <div>{{ activeOrder.salesOrderIdAndPosition }}</div>
      <div class="margin">
        {{ activeOrder.finishedGoodArticles[0]?.externalArticleId }} / {{ activeOrder.finishedGoodArticles[0]?.configurationId }}
      </div>
    </div>
    <div class="active-order__quantity">
      <div class="text__label">{{ 'ACTIVE_ORDER.JOB_QUANTITY' | translate }}</div>
      <div class="text__value">{{ activeOrder.estimatedQuantity | formatQuantity }}</div>
    </div>
    <hr />
    <div class="active-order__navigation">
      <lib-phase-navigation [isReadOnly]="navigationReadOnly">
        <ng-container *ngFor="let phase of orderPhases">
          <lib-phase-navigation-item
            [title]="phase.title | translate"
            [icon]="phase.icon"
            [isActive]="isActive(activeOrder.runPhaseType, phase.phaseType)"
            [isPhaseActive]="isActive(activeOrder.runPhaseType, phase.phaseType)"
            [hasSubItems]="phase.hasSubItems"
          >
            <ng-container *ngFor="let subPhase of phase.subItems">
              <lib-sub-phase-navigation-item
                [title]="subPhase.title | translate"
                [isActive]="isActive(activeOrder.runSubPhaseType, subPhase.subPhaseType)"
                [isSubPhaseActive]="isActive(activeOrder.runSubPhaseType, subPhase.subPhaseType)"
              ></lib-sub-phase-navigation-item>
            </ng-container>
          </lib-phase-navigation-item>
        </ng-container>
      </lib-phase-navigation>
    </div>
  </ng-container>
  <ng-template #noActiveOrder>
    <div class="active-order__title">
      <div class="text__label">{{ 'ACTIVE_ORDER.TITLE' | translate }}</div>
      <div class="text__value">—</div>
    </div>
  </ng-template>
</div>
