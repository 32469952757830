<section class="container-list-template">
  <div class="container-list-template__status-panel">
    <div class="status-panel__columns">
      <ng-content select="lib-container-list-columns"></ng-content>
    </div>
    <div class="status-panel__machine-info">
      <ng-content select="lib-container-list-machine-info"></ng-content>
    </div>
  </div>
  <div class="container-list-template__content">
    <div class="container-list-template__col">
      <ng-content></ng-content>
    </div>
  </div>
</section>
