<div class="item-white-waste">
  <div class="item-white-waste__input">
    <lib-input-number [inputStyle]="{ width: '64px' }" [(ngModel)]="whiteWaste"></lib-input-number>
  </div>
  <div class="item-white-waste__button">
    <lib-button [label]="addButtonLabel" (clicked)="addPalletWhiteWaste()"></lib-button>
  </div>
  <div class="item-white-waste__total-label">
    {{ totalLabel }}
  </div>
  <div class="item-white-waste__total-value">
    {{ item?.whiteWasteQuantity.value }}
  </div>
  <i class="icon-reset item-white-waste__reset" (click)="resetPalletWhiteWaste()"></i>
</div>
