import { Component, OnChanges, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import {
  ArticleDescription,
  LabelPrintResult,
  PrimaryMaterialMounted,
  ProductionOrderInfo,
  WorkCenterDescription
} from 'chronos-core-client';
import { DismountingPalletInformationMapper, SortDirection } from 'chronos-shared';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import * as R from 'ramda';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, share, tap } from 'rxjs/operators';
import { ReplaceDummyModalComponent } from '../replace-dummy-modal/replace-dummy-modal.component';
import { DismountContainerModalComponent } from '../dismount-container-modal/dismount-container-modal.component';
import { DismountDummyPalletModalComponent } from '../dismount-dummy-pallet-modal/dismount-dummy-pallet-modal.component';

@Component({
  selector: 'pt-pallet-list-modal',
  templateUrl: './pallet-list-modal.component.html',
  styleUrls: ['./pallet-list-modal.component.scss'],
  providers: [DialogService]
})
export class PalletListModalComponent implements OnChanges, OnDestroy {
  public workCenter?: WorkCenterDescription;
  public previousPalletList: PrimaryMaterialMounted[] = [];
  public activeArticle?: ArticleDescription;
  public allowQuantityChangeInDismount = false;
  public activeBlockPallets$!: Observable<PrimaryMaterialMounted[]>;
  public previousBlockPallets$!: Observable<PrimaryMaterialMounted[]>;
  public palletsExist$!: Observable<boolean>;
  public previousArticle$!: Observable<ArticleDescription | undefined>;
  public previousProductionOrderInfo$!: Observable<ProductionOrderInfo | undefined>;
  public activeProductionOrderInfo$!: Observable<ProductionOrderInfo | undefined>;

  private palletSequenceSubject = new BehaviorSubject<SortDirection>(SortDirection.ASC);
  private dummyFilterSubject = new BehaviorSubject<boolean>(false);

  public palletSequence$ = this.palletSequenceSubject.asObservable();
  public dummyFilter$ = this.dummyFilterSubject.asObservable();

  private subscriptions = new Subscription();
  public activeBlockPalletsLength = 0;
  public previousBlockPalletsLength = 0;
  public noPalletsToggle = false;

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private workCenterDetailsService: WorkCenterDetailsService,
    private dialogService: DialogService,
    private translateService: TranslateService
  ) {
    this.workCenter = this.config.data?.workCenter;
    this.allowQuantityChangeInDismount = this.config.data?.quantityChangeFlag;
    this.initPalletList();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngOnChanges(): void {
    this.previousBlockPalletsLength > 0 || this.activeBlockPalletsLength > 0
      ? (this.noPalletsToggle = false)
      : (this.noPalletsToggle = true);
  }

  public initPalletList(): void {
    if (this.workCenter) {
      const palletListSource = this.workCenterDetailsService.getPalletList(this.workCenter.workCenterId).pipe(share());

      const activeMaterialBlock: Observable<PrimaryMaterialMounted[]> = palletListSource.pipe(
        map((palletList) => palletList.activeMaterialBlock?.mountedMaterials ?? [])
      );
      const previousMaterialBlock: Observable<PrimaryMaterialMounted[]> = palletListSource.pipe(
        map((palletList) => palletList.previousProductionOrder?.mountedMaterials ?? []),
        tap((previousPalletList) => (this.previousPalletList = previousPalletList))
      );

      this.previousBlockPallets$ = combineLatest([previousMaterialBlock, this.dummyFilter$, this.palletSequence$]).pipe(
        map(([previousMaterial, showOnlyDummy, sortOrder]) => this.getPalletListInOrder(previousMaterial, showOnlyDummy, sortOrder, false))
      );

      this.activeBlockPallets$ = combineLatest([activeMaterialBlock, this.dummyFilter$, this.palletSequence$]).pipe(
        map(([activeMaterial, showOnlyDummy, sortOrder]) => this.getPalletListInOrder(activeMaterial, showOnlyDummy, sortOrder, true))
      );

      this.subscriptions.add(
        palletListSource
          .pipe(map((palletList) => palletList.activeMaterialBlock?.article))
          .subscribe((activeArticle) => (this.activeArticle = activeArticle))
      );

      this.previousArticle$ = palletListSource.pipe(map((palletList) => palletList.previousProductionOrder?.article));

      this.previousProductionOrderInfo$ = palletListSource.pipe(
        map((palletList) => palletList.previousProductionOrder?.productionOrderInfo)
      );

      this.activeProductionOrderInfo$ = palletListSource.pipe(map((palletList) => palletList.activeMaterialBlock?.productionOrderInfo));
    } else {
      throw new Error(`PalletListModalComponent.initPalletList: workCenter = ${typeof this.workCenter}`);
    }
  }

  public onPalletOrderChange(sortDirection: SortDirection): void {
    this.palletSequenceSubject.next(sortDirection === SortDirection.ASC ? SortDirection.ASC : SortDirection.DESC);
  }

  public addPalletWhiteWaste([mountedMaterialId, whiteWaste]: [number, number]) {
    if (this.workCenter) {
      this.workCenterDetailsService
        .addContainerWhiteWaste(mountedMaterialId, this.workCenter.workCenterId, { value: whiteWaste, unitId: '' })
        .subscribe(() => {
          this.initPalletList();
        });
    } else {
      throw new Error(`PalletListModalComponent.addPalletWhiteWaste: workCenter = ${typeof this.workCenter}`);
    }
  }

  public resetPalletWhiteWaste(mountedMaterialId: number) {
    if (this.workCenter) {
      this.workCenterDetailsService.removeContainerWhiteWaste(mountedMaterialId, this.workCenter.workCenterId).subscribe(() => {
        this.initPalletList();
      });
    } else {
      throw new Error(`PalletListModalComponent.resetPalletWhiteWaste: workCenter = ${typeof this.workCenter}`);
    }
  }

  public onReplaceClick([mountedMaterialId, dummyContainerId]: [number, number]): void {
    if (this.workCenter) {
      const modal = this.dialogService.open(ReplaceDummyModalComponent, {
        header: this.translateService.instant('PILE_TURNER.MACHINE_LOCATION'),
        width: '1650px',
        data: {
          mountedMaterialId,
          dummyContainerId,
          workCenterId: this.workCenter.workCenterId,
          isMaterialQuantityRequired: false
        }
      });

      modal.onClose.subscribe((mounted: boolean) => {
        if (mounted) {
          this.initPalletList();
        }
      });
    } else {
      throw new Error(`PalletListModalComponent.onReplaceClick: workCenter = ${typeof this.workCenter}`);
    }
  }

  public onDismountClick(pallet: PrimaryMaterialMounted): void {
    if (pallet.container?.isVirtualContainer) {
      this.openDummyPalletDismountingModal(pallet);
    } else {
      this.openPalletDismountingModal(pallet);
    }
  }

  public onDummyFilterChange(filterValue: boolean): void {
    this.dummyFilterSubject.next(filterValue);
  }

  public openPalletDismountingModal(pallet: PrimaryMaterialMounted): void {
    if (this.activeArticle && this.workCenter) {
      const modal = this.dialogService.open(DismountContainerModalComponent, {
        header: this.translateService.instant('DISMOUNTING.DISMOUNTING'),
        data: {
          workCenter: this.workCenter,
          containerId: pallet.container?.containerId,
          article: this.activeArticle,
          dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(pallet, this.activeArticle),
          allowQuantityChangeInDismount: this.allowQuantityChangeInDismount
        }
      });

      modal.onClose.subscribe((dismounted) => {
        if (dismounted) {
          this.initPalletList();
        }
      });
    } else {
      throw new Error(
        `PalletListModalComponent.dismountDummyPallet: workCenter = ${typeof this.workCenter}, activeArticle = ${typeof this.activeArticle}`
      );
    }
  }

  private openDummyPalletDismountingModal(pallet: PrimaryMaterialMounted): void {
    const { container } = pallet;
    const isLabelPrinted = container.labelPrintResult === LabelPrintResult.DONE;

    this.dialogService
      .open(DismountDummyPalletModalComponent, {
        header: this.translateService.instant('DISMOUNT_DUMMY_PALLET_FORM.DISMOUNT_DUMMY_PALLET'),
        data: {
          workCenterId: this.workCenter.workCenterId,
          containerId: pallet.container?.containerId,
          remainingQuantity: container.bomGoodQuantity.value,
          isLabelPrinted,
          dismountingPalletInformation: DismountingPalletInformationMapper.mapFrom(pallet, this.activeArticle)
        }
      })
      .onClose.subscribe((dismounted) => {
        if (dismounted) {
          this.initPalletList();
        }
      });
  }

  public closeModal(): void {
    this.ref.close();
  }

  private getPalletListInOrder(
    pallets: PrimaryMaterialMounted[],
    showOnlyDummy: boolean,
    sortOrder: SortDirection,
    activeMaterialToggle: boolean
  ): PrimaryMaterialMounted[] {
    const sort = this.getSort(sortOrder);
    const filterDummy = this.getDummyFilter(showOnlyDummy);

    activeMaterialToggle ? (this.activeBlockPalletsLength = pallets.length) : (this.previousBlockPalletsLength = pallets.length);

    return sort(filterDummy(pallets));
  }

  private getSort(sortDirection: SortDirection) {
    const sortDirectionFunction = sortDirection === SortDirection.ASC ? R.ascend : R.descend;
    return R.sort<PrimaryMaterialMounted>(sortDirectionFunction(R.prop('materialSequence')));
  }

  private getDummyFilter(showOnlyDummy: boolean) {
    const showDummy = (m: PrimaryMaterialMounted) => (showOnlyDummy ? m.container?.isVirtualContainer : true);
    return (material: PrimaryMaterialMounted[]) => material.filter(showDummy);
  }
}
