import { Component, OnInit } from '@angular/core';
import { WorkCenterDetailsService } from '@pt/modules/pile-turner/services/work-center-details/work-center-details.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DismountingPalletInformation, LoadingNotificationService, LogService } from 'chronos-shared';
import { notificationTopic } from '@pt/shared/utils';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'pt-dismount-dummy-pallet-modal',
  templateUrl: './dismount-dummy-pallet-modal.component.html',
  styleUrls: ['./dismount-dummy-pallet-modal.component.scss']
})
export class DismountDummyPalletModalComponent implements OnInit {
  public dismountingPalletInformation: DismountingPalletInformation;
  public isLabelPrinted: boolean;

  public readonly LOADING_TOPIC = notificationTopic.modalDismountContainer;
  constructor(
    private config: DynamicDialogConfig,
    private ref: DynamicDialogRef,
    private workCenterDetailsService: WorkCenterDetailsService
  ) {}

  public ngOnInit(): void {
    this.dismountingPalletInformation = this.config.data?.dismountingPalletInformation;
    this.isLabelPrinted = this.config.data?.isLabelPrinted === true;
  }

  public dismountContainer(): void {
    LoadingNotificationService.publish(this.LOADING_TOPIC, true);
    this.workCenterDetailsService
      .dismountContainer(
        this.config.data.containerId,
        this.config.data.workCenterId,
        this.dismountingPalletInformation.mountedMaterialId,
        this.config.data.remainingQuantity,
        this.isLabelPrinted
      )
      .pipe(
        finalize(() => {
          LoadingNotificationService.publish(this.LOADING_TOPIC, false);
        })
      )
      .subscribe(() => {
        LogService.success('SUCCESS_MESSAGE.MATERIAL_DISMOUNTED');
        this.ref.close(true);
      });
  }

  public onCloseModal(): void {
    this.ref.close(false);
  }
}
