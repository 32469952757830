<!-- HEADER -->
<pt-header></pt-header>

<div class="content">
  <!-- Left side page menu -->
  <nav class="main-menu">
    <pt-main-menu></pt-main-menu>
  </nav>
  <!-- main order pages area -->
  <main class="main-section">
    <router-outlet></router-outlet>
  </main>
  <!-- Right side ASIDE area-->
  <pt-aside></pt-aside>
</div>
