<div [ngClass]="alternativeArticlesToggle && alternativeArticles?.length > 0 ? 'articles' : 'article-info'">
  <div *ngIf="article" class="article-info">
    <div>{{ article.articleName }}</div>
    <div>{{ article.externalArticleId }}</div>
    <div>{{ article.externalConfigurationId }}</div>
  </div>

  <div *ngIf="alternativeArticlesToggle && alternativeArticles?.length > 0" class="alternative-article-info">
    <div>{{ alternativeArticles[0]?.articleName }}</div>
    <div>{{ alternativeArticles[0]?.externalArticleId }}</div>
    <div>{{ alternativeArticles[0]?.externalConfigurationId }}</div>
  </div>

  <div
    *ngIf="alternativeArticlesToggle && alternativeArticles?.length > 1 && alternativeArticles[0]?.externalArticleId"
    class="more-articles"
  >
    <i class="pi pi-ellipsis-h" (click)="openArticleDialog()"></i>
  </div>
</div>
