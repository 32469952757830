import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { StorageKey, StorageService } from 'chronos-shared';
import { PileTurnerListDsService } from '@pt/core/data-services/pile-turner-list-ds';
import { RelatedWorkCenter } from 'chronos-core-client';

@Injectable({
  providedIn: 'root'
})
export class PileTurnerWorkCenterService {
  private currentPileTurner = new BehaviorSubject<number>(0);
  public pileTurnerId$: Observable<number> = this.currentPileTurner.asObservable();

  private readonly STORAGE_KEY = StorageKey.pileTurnerId;

  constructor(private storageService: StorageService, private pileTurnerListDsService: PileTurnerListDsService) {}

  public initializePileTurnerId(): Observable<boolean> {
    const pileTurnerId = this.getPileTurnerFromStorage();
    if (pileTurnerId) {
      return this.pileTurnerExists(pileTurnerId).pipe(
        tap((isValid) => {
          if (isValid) {
            this.currentPileTurner.next(pileTurnerId);
          }
        })
      );
    }
    return of(false);
  }

  public getPileTurnerId(): number {
    return this.currentPileTurner.getValue();
  }

  public setPileTurnerId(pileTurnerId: number): void {
    this.storageService.setItem(this.STORAGE_KEY, pileTurnerId?.toString());
  }

  private getPileTurnerFromStorage(): number | null {
    const storagePileTurnerId = this.storageService.getItem(this.STORAGE_KEY);
    return !R.isNil(storagePileTurnerId) ? Number(storagePileTurnerId) : null;
  }

  private pileTurnerExists(pileTurnerId: number): Observable<boolean> {
    const containsPileTurnerId = (list: RelatedWorkCenter[]) => R.any(R.compose(R.equals(pileTurnerId), R.prop('id')), list);
    return this.pileTurnerListDsService.getPileTurners().pipe(map(containsPileTurnerId));
  }
}
