import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppInitService } from '@pt/app-init.service';
import { CoreModule } from '@pt/core/core.module';
import { PileTurnerModule } from '@pt/modules/pile-turner/pile-turner.module';
import { ShellModule } from '@pt/shell/shell.module';
import { webSocketAccessTokenFactory } from 'chronos-core-client';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ApiAuthorizationModule,
  AUTHORIZE_INTERCEPTOR_MATCHER,
  AUTHORIZE_LOGIN_HOOKS,
  AuthorizeAccessTokenFactory,
  AuthorizeInterceptor,
  AuthorizeService,
  ChronosSharedModule,
  HttpErrorInterceptor,
  sameOriginMatcher
} from 'chronos-shared';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { registerLocaleData } from '@angular/common';
import localeAt from '@angular/common/locales/en-AT';
import localEs from '@angular/common/locales/es';
import { environment } from '@pt-env/environment';
import { PileTurnerSelectionModule } from '@pt/modules/pile-turner-selection/pile-turner-selection.module';
import { FrontEndFeaturesInitService } from './frontend-features-init.service';
import { ApmErrorHandler, ApmModule, ApmService } from '@elastic/apm-rum-angular';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?cache-buster=${new Date().getTime()}`);
}

function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => appInitService.Init();
}

function initializeWorkCenterOnLogin(frontEndFeaturesInitService: FrontEndFeaturesInitService) {
  return (): Promise<any> => frontEndFeaturesInitService.initializeWorkCenter();
}

registerLocaleData(localeAt);
registerLocaleData(localEs);

@NgModule({
  declarations: [AppComponent],
  imports: [
    ApmModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    PileTurnerModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ShellModule,
    PileTurnerSelectionModule,
    ChronosSharedModule.forRoot({
      coreRootUrl: environment.apiUrl,
      signalrBaseUrl: environment.hubUrl,
      pandaRootUrl: environment.pandaUrl,
      liveRootUrl: environment.liveUrl
    }),
    ApiAuthorizationModule.forRoot({
      authority: 'https://portal.chronos.tietoevry.com/authenticator',
      clientId: 'chronos-dev'
    })
  ],
  providers: [
    ApmService,
    { provide: ErrorHandler, useClass: ApmErrorHandler },
    { provide: LOCALE_ID, useValue: 'en-AT' },
    { provide: AUTHORIZE_INTERCEPTOR_MATCHER, useValue: sameOriginMatcher, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true },
    { provide: webSocketAccessTokenFactory, useClass: AuthorizeAccessTokenFactory, deps: [AuthorizeService] },
    {
      provide: AUTHORIZE_LOGIN_HOOKS,
      useFactory: initializeWorkCenterOnLogin,
      deps: [FrontEndFeaturesInitService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
