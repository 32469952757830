import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PileTurnerScanContainerResponse, PileTurnerWorkCenter } from 'chronos-core-client';
import { notificationTopic } from '@pt/shared/utils';

@Component({
  selector: 'pt-selected-pallet',
  templateUrl: './selected-pallet.component.html',
  styleUrls: ['./selected-pallet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedPalletComponent {
  @Input() public selectedPallet?: PileTurnerScanContainerResponse;
  @Input() public workCenterSelected?: PileTurnerWorkCenter;

  @Output() public mountButtonClicked = new EventEmitter<number>();
  @Output() public cancelButtonClicked = new EventEmitter<void>();

  public readonly LOADING_TOPIC = notificationTopic.mountPalletAction;

  private readonly DEFAULT_WHITE_WASTE = 0;
  public whiteWaste = this.DEFAULT_WHITE_WASTE;

  public mountPallet(): void {
    this.mountButtonClicked.emit(this.whiteWaste);
    this.resetWhiteWaste();
  }

  public cancelPalletMounting(): void {
    this.cancelButtonClicked.emit();
    this.resetWhiteWaste();
  }

  public onWhiteWasteBlur(): void {
    if (!this.whiteWaste) {
      this.resetWhiteWaste();
    }
  }

  private resetWhiteWaste(): void {
    this.whiteWaste = this.DEFAULT_WHITE_WASTE;
  }
}
