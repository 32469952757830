<lib-dismount-container-form
  [dismountingPalletInformation]="dismountingPalletInformation"
  [article]="article"
  (returnSheetChanged)="onReturnSheetsChange($event)"
  (printLabelChanged)="onPrintLabelChange($event)"
  (isValid)="onValidityChange($event)"
  [allowQuantityChange]="allowQuantityChange"
></lib-dismount-container-form>
<lib-modal-footer
  [submitLabel]="'DISMOUNTING.DISMOUNT'"
  (submitClicked)="dismountContainer()"
  (declineClicked)="onCancel()"
  [submitDisabled]="!isFormValid"
  [whenLoadingTopic]="LOADING_TOPIC"
></lib-modal-footer>
