import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PileTurnerProductionOrder } from 'chronos-core-client';
import { DialogService } from 'primeng/dynamicdialog';
import { TranslateService } from '@ngx-translate/core';
import { CreatingDummyModalComponent } from '../creating-dummy-modal/creating-dummy-modal.component';
import { Dummy } from 'chronos-shared';

@Component({
  selector: 'pt-production-order',
  templateUrl: './production-order.component.html',
  styleUrls: ['./production-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductionOrderComponent {
  @Input() public productionOrder?: PileTurnerProductionOrder;
  @Input() public workCenterId?: number;

  @Output() public dummyPalletCreated = new EventEmitter<null>();

  constructor(private dialogService: DialogService, private translateService: TranslateService) {}

  public openDummyPalletModal(articleId: number): void {
    this.dialogService
      .open(CreatingDummyModalComponent, {
        data: {
          workCenterId: this.workCenterId,
          dummyPallet: {
            articleId,
            materialBlockId: this.productionOrder?.materialBlockId,
            bomUnitId: this.productionOrder.article.bomUnitId,
            productionOrderId: this.productionOrder?.productionOrderId
          } as Dummy
        },
        header: this.translateService.instant('MOUNTING.CREATE_DUMMY_PALLET')
      })
      .onClose.subscribe(() => {
        this.dummyPalletCreated.emit();
      });
  }
}
