import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PileTurnerDsService } from '@pt/core/data-services';
import { MaterialAvailableAtLocation, PileTurnerScanContainerResponse } from 'chronos-core-client';

@Injectable()
export class SupplyService {
  constructor(private pileTurnerDsService: PileTurnerDsService) {}

  public getAvailableForMount(): Observable<MaterialAvailableAtLocation> {
    return this.pileTurnerDsService.getMaterialAvailableAtPileTurner();
  }

  public scanPallet(identificationCode: string, containerId?: number): Observable<PileTurnerScanContainerResponse> {
    return this.pileTurnerDsService.scanPallet(identificationCode, containerId);
  }
}
